/* We need to add display:inline in order to align the '>>' of the 'read more' link */
.post-excerpt p {
	display:inline;
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
	"syntax"
;

.center-image
{
    margin: 0 auto;
    display: block;
}

.PageNavigation {
    a {
        
    }
}

.bg-lightblue {
    background: #e8f3ec !important;
}

.jumbotron {
    background-color: #343a40;
}

.jumbotron.fortags {
    background-image: none;
}

.avatar-image {
    border-radius: 50%;    
}

#comments {
    margin-top: 130px;    
}

.article-post {
    h5 {
        margin-top:40px;
        margin-bottom: 15px;
    }
    h2 {
        margin-top: 60px;
    }    

    a {
        color: rgba(0,0,0,.7);
        word-break: break-word;
        -webkit-box-shadow: #3eb0ef 0 -1px 0 inset;
        box-shadow: inset 0 -1px 0 #3eb0ef;
    }

    strong {
        color: #000;
    }

    a:hover {
        color: #3eb0ef;
        text-decoration: none;
    }

    table{
        border-collapse: collapse;
        border-spacing: 0;
        border:2px solid #efefef;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    th{
        border:2px solid #efefef;
        padding: 10px;
        background-color: #e8f3ec;
    }

    td{
        border:1px solid #efefef;
        padding: 20px;
    }

    .success {
        background-color: #28a745;
    }

    .warning {
        background-color: #ffc107;
    }

    .danger {
        background-color: #dc3545;
    }

    .text-padding {
        padding: 5px;
    }
}

.readingtime {
    color: rgba(0, 0, 0, 0.54);    
}

.featured-image {
    margin-bottom: 100px;   
}

.post-top-meta {
    margin-top: 50px;
    margin-bottom: 100px;
}

.after-post-tags {
    margin-top: 50px;
    border-bottom: 1px solid #00ab6b;
}

ul.slim-toc > li > ul {
    display: none;
}

ul.slim-toc > li  {
    color: #3eb0ef;
    text-decoration: none;
}

.blockquote-success {
    border-left: 4px solid #00ab6b;
}

.blockquote-warning {
    border-left: 4px solid #ffc107;
}

.blockquote-danger {
    border-left: 4px solid #dc3545;
}